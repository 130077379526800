import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	AppBar,
	Toolbar,
	Button,
	ButtonBase,
	Hidden,
	Drawer,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../images/logo.svg";
import { useHistory, useLocation } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		color: "white",
		paddingTop: "10px"
	},
	menuButton: {
		marginRight: "10px",
		borderRadius: "2px",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.2)"
		}
	},
	menuButtonBold: {
		marginRight: "10px",
		fontWeight: "bold",
		borderRadius: "2px",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.2)"
		}
	},
	title: {
		flexGrow: 1,
		fontWeight: "bold"
	},
	actionButton: {
		background: "#ffc000",
		color: "white",
		borderRadius: "2px",
		padding: "10px 25px",
		"&:hover": {
			backgroundColor: "#e0a902"
		}
	},
	bar: {
		padding: "0 5vw"
	},
	imageMobile: {
		height: "30px"
	},
	menuDrawer: {
		width: "90%"
	},
	mobileMenuItem: {
		textTransform: "uppercase"
	},
	mobileMenuItemPrimary: {
		textTransform: "uppercase",
		color: "#ffc000"
	},
	mobileMenuItemBold: {
		textTransform: "uppercase",
		fontWeight: "600 !important"
	},
	mobileMenuItemPrimaryBold: {
		textTransform: "uppercase",
		color: "#ffc000",
		fontWeight: "600 !important"
	}
}));

export default () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [menuOpen, setMenuOpen] = useState(false);
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<div className={classes.root}>
			<AppBar
				position="static"
				color="transparent"
				elevation={0}
				className={classes.bar}
			>
				<Toolbar disableGutters>
					<div className={classes.title}>
						<ButtonBase
							onClick={() => {
								history.push("/");
							}}
						>
							<img
								src={logo}
								alt="Got my city"
								className={
									mobile ? classes.imageMobile : classes.image
								}
							/>
						</ButtonBase>
					</div>
					<Hidden smDown>
						<Button
							color="inherit"
							size="large"
							onClick={() => {
								history.push("/");
							}}
							className={
								location && location.pathname === "/"
									? classes.menuButtonBold
									: classes.menuButton
							}
						>
							Home
						</Button>
						<Button
							color="inherit"
							size="large"
							onClick={() => {
								history.push("/faq");
							}}
							className={
								location && location.pathname === "/faq"
									? classes.menuButtonBold
									: classes.menuButton
							}
						>
							Got Questions?
						</Button>
						<Button
							size="large"
							className={classes.actionButton}
							onClick={() => {
								history.push("/register");
							}}
						>
							Get Sharing
						</Button>
					</Hidden>
					<Hidden mdUp implementation="css">
						<MenuIcon
							fontSize="large"
							onClick={() => {
								setMenuOpen(true);
							}}
						/>
						<Drawer
							anchor="right"
							open={menuOpen}
							onClose={() => {
								setMenuOpen(false);
							}}
						>
							<List>
								<ListItem
									button
									onClick={() => {
										history.push("/");
										setMenuOpen(false);
									}}
								>
									<ListItemText
										primary="Home"
										className={
											location &&
											location.pathname === "/"
												? classes.mobileMenuItemBold
												: classes.mobileMenuItem
										}
									/>
								</ListItem>
								<ListItem
									button
									onClick={() => {
										history.push("/faq");
										setMenuOpen(false);
									}}
								>
									<ListItemText
										primary="Got Questions?"
										className={
											location &&
											location.pathname === "/faq"
												? classes.mobileMenuItemBold
												: classes.mobileMenuItem
										}
									/>
								</ListItem>
								<ListItem
									button
									onClick={() => {
										history.push("/register");
										setMenuOpen(false);
									}}
								>
									<ListItemText
										primary="Get Sharing"
										color="primary"
										className={
											location &&
											location.pathname === "/register"
												? classes.mobileMenuItemPrimaryBold
												: classes.mobileMenuItemPrimary
										}
									/>
								</ListItem>
							</List>
						</Drawer>
					</Hidden>
				</Toolbar>
			</AppBar>
		</div>
	);
};
