import React from "react";
import { Route, Redirect } from "react-router";
import netlifyIdentity from "netlify-identity-widget";

export default ({ component: Component, ...rest }) => {
	const user = netlifyIdentity.currentUser();
	return (
		<Route
			{...rest}
			render={props =>
				user ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
};
