import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Modal,
	Button,
	Chip,
	Select,
	InputLabel,
	MenuItem,
	FormControl
} from "@material-ui/core";

const HOODS = gql`
	query Hoods($city: CityWhereInput!) {
		hoods(
			where: {
				AND: [{ city: $city }, { businesses_some: { id_not: null } }]
			}
			orderBy: name_ASC
		) {
			name
			id
		}
	}
`;

const CITIES = gql`
	{
		cities(
			where: { businesses_some: { id_not: null } }
			orderBy: name_ASC
		) {
			id
			name
			province
		}
	}
`;

const BUSINESS_TYPES = gql`
	{
		businessTypes(orderBy: name_ASC) {
			id
			name
		}
	}
`;

function getModalStyle() {
	const top = 30;
	return {
		top: `${top}%`,
		margin: "auto"
	};
}

const useStyles = makeStyles(theme => ({
	container: {
		margin: "0px 5vw"
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		position: "absolute",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		outline: "none",
		borderRadius: "5px",
		color: "#424242"
	},
	paperMobile: {
		position: "absolute",
		width: "80vw",
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		outline: "none",
		borderRadius: "5px",
		color: "#424242"
	},
	actionButton: {
		background: "#ffc000",
		color: "white",
		borderRadius: "2px",
		padding: "10px 25px",
		fontWeight: "bold",
		"&:hover": {
			backgroundColor: "#e0a902"
		},
		"&:disabled": {
			backgroundColor: "#ffc00069",
			color: "white"
		}
	},
	cancelButton: {
		background: "#f5f5f5",
		color: "#424242",
		borderRadius: "2px",
		padding: "10px 25px",
		fontWeight: "bold"
	},
	citySelect: {
		marginTop: "15px"
	},
	hoodSelect: {
		marginTop: "15px"
	},
	categorySelect: {
		marginTop: "15px",
		marginBottom: "40px"
	},
	filterLabel: {
		marginRight: "10px",
		textTransform: "uppercase"
	},
	filterChipContainer: {
		marginTop: "20px",
		display: "flex",
		alignItems: "center"
	},
	chip: {
		borderRadius: "30px",
		border: "4px solid",
		fontSize: "16px",
		fontWeight: "800",
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		minWidth: 0,
		maxWidth: "none",
		padding: "20px 10px",
		"&:hover": {
			border: "4px solid",
			background: "rgba(0, 162, 255, 0.2)"
		},
		textTransform: "uppercase"
	},
	chipMobile: {
		borderRadius: "30px",
		border: "4px solid",
		fontWeight: "800",
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		minWidth: 0,
		maxWidth: "none",
		"&:hover": {
			border: "4px solid",
			background: "rgba(0, 162, 255, 0.2)"
		},
		textTransform: "uppercase"
	},
	chipSeparator: {
		fontWeight: 900,
		color: "#00a2ff",
		fontSize: "24px",
		padding: "0 10px"
	}
}));

export default ({
	setHood,
	hood,
	setCity,
	city,
	setBusinessType,
	businessType,
	setIsFiltered,
	isFiltered,
	mobile
}) => {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(false);
	const { data: citiesData } = useQuery(CITIES);
	const { data: hoodsData } = useQuery(HOODS, {
		variables: {
			city: { id: city && city.id ? city.id : "" }
		}
	});

	const { data: businessTypesData } = useQuery(BUSINESS_TYPES);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCityChange = (e, child) => {
		setCity({ id: child.key, name: child.props.children });
		setHood({ id: "", name: "All" });
	};

	const handleResetFilter = () => {
		setCity({ id: "", name: "" });
		setHood({ id: "", name: "" });
		setBusinessType({ id: "", name: "" });
		setIsFiltered(false);
		handleClose();
	};

	const body = (
		<div
			style={modalStyle}
			className={mobile ? classes.paperMobile : classes.paper}
		>
			<h2 id="modal-title">Filter Businesses</h2>
			<div id="modal-body">Filter by city and/or category</div>
			<FormControl
				variant="outlined"
				color="primary"
				className={classes.citySelect}
				fullWidth
			>
				<InputLabel id="city-label">Select a City</InputLabel>
				<Select
					labelId="city-label"
					id="city"
					name="city"
					onChange={handleCityChange}
					label="Call to Action"
					value={city.id}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{citiesData &&
						citiesData.cities.map(({ id, name }) => (
							<MenuItem value={id} key={id}>
								{name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
			{city && city.id && (
				<FormControl
					variant="outlined"
					color="primary"
					className={classes.hoodSelect}
					fullWidth
				>
					<InputLabel id="hood-label">Select a Hood</InputLabel>
					<Select
						labelId="hood-label"
						id="hood"
						name="hood"
						onChange={(e, child) => {
							setHood({
								id: child.key,
								name: child.props.children
							});
						}}
						label="Select a Hood"
						value={hood.id}
					>
						<MenuItem value="">
							<em>All</em>
						</MenuItem>
						{hoodsData &&
							hoodsData.hoods.map(({ id, name }) => (
								<MenuItem value={id} key={id}>
									{name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			)}
			<FormControl
				variant="outlined"
				color="primary"
				className={classes.categorySelect}
				fullWidth
			>
				<InputLabel id="type-label">Select a Category</InputLabel>
				<Select
					labelId="type-label"
					id="type"
					name="type"
					onChange={(e, child) => {
						setBusinessType({
							id: child.key,
							name: child.props.children
						});
					}}
					label="Call to Action"
					value={businessType.id}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{businessTypesData &&
						businessTypesData.businessTypes.map(({ id, name }) => (
							<MenuItem value={id} key={id}>
								{name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
			<Box display="flex" alignItems="flex-start">
				<Box flexGrow={1}>
					<Button
						color="inherit"
						size="small"
						variant="contained"
						disableElevation
						className={classes.cancelButton}
						onClick={handleClose}
					>
						Cancel
					</Button>
				</Box>
				<Box alignSelf="flex-end">
					<Button
						color="inherit"
						size="small"
						variant="contained"
						disableElevation
						className={classes.actionButton}
						disabled={
							!(
								(city && city.id) ||
								(businessType && businessType.id)
							)
						}
						onClick={e => setIsFiltered(true)}
					>
						Apply
					</Button>
				</Box>
			</Box>
		</div>
	);

	if (!isFiltered) {
		return (
			<div className={classes.container}>
				<Button
					color="inherit"
					size="large"
					onClick={handleOpen}
					className={classes.actionButton}
				>
					Filter Businesses
				</Button>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					className={classes.modal}
				>
					{body}
				</Modal>
			</div>
		);
	} else {
		return (
			<div className={classes.container}>
				<Box component="span" className={classes.filterLabel}>
					Filters
				</Box>
				<Button
					color="inherit"
					size="small"
					variant="contained"
					disableElevation
					className={classes.cancelButton}
					onClick={handleResetFilter}
				>
					Clear Filters
				</Button>
				<Box className={classes.filterChipContainer}>
					{city && city.id && city.name ? (
						<Chip
							variant="outlined"
							size="large"
							className={
								mobile ? classes.chipMobile : classes.chip
							}
							label={city.name}
						/>
					) : (
						""
					)}
					{city &&
					city.id &&
					city.name &&
					((hood && hood.id && hood.name) ||
						(businessType &&
							businessType.id &&
							businessType.name)) ? (
						<Box component="span" className={classes.chipSeparator}>
							{" "}
							:{" "}
						</Box>
					) : (
						""
					)}
					{hood && hood.id && hood.name ? (
						<Chip
							variant="outlined"
							size="large"
							className={
								mobile ? classes.chipMobile : classes.chip
							}
							label={hood.name}
						/>
					) : (
						""
					)}
					{hood &&
					hood.id &&
					hood.name &&
					(businessType && businessType.id && businessType.name) ? (
						<Box component="span" className={classes.chipSeparator}>
							{" "}
							:{" "}
						</Box>
					) : (
						""
					)}
					{businessType && businessType.id && businessType.name ? (
						<Chip
							variant="outlined"
							size="large"
							className={
								mobile ? classes.chipMobile : classes.chip
							}
							label={businessType.name}
						/>
					) : (
						""
					)}
				</Box>
			</div>
		);
	}
};
