import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#ffc000"
		},
		secondary: {
			main: "#00a2ff",
			light: "#97d8fd"
		},
		default: {
			main: "#f5f5f5"
		}
	}
});

export default theme;
