import React, { useState } from "react";
import {
	Typography,
	Button,
	TextField,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListSubheader
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hero from "../../components/Hero";
import BusinessGridContainer from "../../components/BusinessGrid";
import netlifyIdentity from "netlify-identity-widget";
import { useHistory } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";

const CITIES = gql`
	{
		cities(orderBy: province_ASC) {
			id
			name
			province
		}
	}
`;

const CREATE_CITY = gql`
	mutation CreateCity($data: CityCreateInput!) {
		createCity(data: $data) {
			id
		}
	}
`;

const CREATE_HOOD = gql`
	mutation CreateHood($data: HoodCreateInput!) {
		createHood(data: $data) {
			id
		}
	}
`;

const organiseCities = cities => {
	let out = {};

	cities.map(city => {
		if (out[city.province]) {
			out[city.province].push(city);
		} else {
			out[city.province] = [city];
		}

		return null;
	});

	return Object.entries(out);
};

const useStyles = makeStyles(theme => ({
	adminHeader: {
		margin: "30px 5vw 30px 5vw",
		color: "#424242"
	},
	logoutButton: {
		borderRadius: "2px",
		color: "white"
	},
	logoutBtnContainer: {
		marginLeft: "5vw",
		marginTop: "30px"
	},
	container: {
		marginLeft: "5vw"
	}
}));

export default () => {
	const classes = useStyles();
	const history = useHistory();

	const [cityName, setCityName] = useState("");
	const [cityProvince, setCityProvince] = useState("");
	const [hoodName, setHoodName] = useState("");
	const [hoodCity, setHoodCity] = useState("");

	const { data } = useQuery(CITIES);
	const [createCity] = useMutation(CREATE_CITY, {
		onCompleted: () => {
			setCityName("");
			setCityProvince("");
		}
	});

	const [createHood] = useMutation(CREATE_HOOD, {
		onCompleted: () => {
			setHoodName("");
			setHoodCity("");
		}
	});

	return (
		<>
			<Hero page="admin" />
			<div className={classes.logoutBtnContainer}>
				<Button
					variant="contained"
					color="primary"
					disableElevation
					className={classes.logoutButton}
					onClick={() => {
						netlifyIdentity.logout();
						history.push("/");
					}}
				>
					Log out
				</Button>
			</div>

			<Typography variant="h5" className={classes.adminHeader}>
				Add a City
			</Typography>
			<div className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<TextField
							id="cityName"
							name="cityName"
							label="City Name"
							variant="outlined"
							color="secondary"
							fullWidth
							onChange={e => setCityName(e.target.value)}
							value={cityName}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<TextField
							id="cityProvince"
							name="cityProvince"
							label="Province / State"
							variant="outlined"
							color="secondary"
							fullWidth
							onChange={e => setCityProvince(e.target.value)}
							value={cityProvince}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							className={classes.logoutButton}
							onClick={() => {
								let data = {
									name: cityName,
									province: cityProvince
								};
								createCity({ variables: { data } });
							}}
						>
							Add City
						</Button>
					</Grid>
				</Grid>
			</div>
			<Typography variant="h5" className={classes.adminHeader}>
				Add a Hood
			</Typography>
			<div className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<TextField
							id="hoodName"
							name="hoodName"
							label="Hood Name"
							variant="outlined"
							color="secondary"
							fullWidth
							onChange={e => setHoodName(e.target.value)}
							value={hoodName}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<FormControl
							variant="outlined"
							className={classes.field}
							fullWidth
							color="secondary"
						>
							<InputLabel id="city-label">City</InputLabel>
							<Select
								labelId="city-label"
								id="city"
								name="city"
								value={hoodCity}
								onChange={e => {
									setHoodCity(e.target.value);
								}}
								label="City"
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{data &&
									data.cities &&
									organiseCities(data.cities).map(
										province => [
											<ListSubheader key={province[0]}>
												{province[0]}
											</ListSubheader>,
											province[1].map(({ id, name }) => (
												<MenuItem value={id} key={id}>
													{name}
												</MenuItem>
											))
										]
									)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							className={classes.logoutButton}
							onClick={() => {
								let data = {
									name: hoodName,
									city: {
										connect: {
											id: hoodCity
										}
									}
								};

								createHood({ variables: { data } });
							}}
						>
							Add Hood
						</Button>
					</Grid>
				</Grid>
			</div>

			<Typography variant="h5" className={classes.adminHeader}>
				Unverified Businesses
			</Typography>

			<BusinessGridContainer admin={true} />
		</>
	);
};
