import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Paper, Button, CircularProgress } from "@material-ui/core";
import BusinessGrid from "./BusinessGrid";
import { makeStyles } from "@material-ui/core/styles";

const BUSINESSES = gql`
	query Businesses(
		$where: BusinessWhereInput
		$skip: Int
		$first: Int
		$orderBy: BusinessOrderByInput
	) {
		businesses(
			where: $where
			orderBy: $orderBy
			skip: $skip
			first: $first
		) {
			id
			businessName
			hood {
				name
			}
			city {
				name
			}
			businessType {
				name
			}
			ctaType {
				name
			}
			description
			imageUrl
			ctaLink
		}
	}
`;

const useStyles = makeStyles(theme => ({
	container: {
		margin: "30px 5vw"
	},
	buttonContainer: {
		margin: "60px 5vw",
		textAlign: "center"
	},
	loadButton: {
		backgroundColor: "#F5F5F5",
		borderRadius: "2px"
	},
	progress: {
		color: "white",
		position: "absolute",
		left: "50%",
		marginLeft: -12
	}
}));

export default ({
	searchString,
	hood,
	city,
	businessType,
	isFiltered,
	admin
}) => {
	const classes = useStyles();
	let variables = {
		where: { businessName_contains: searchString, verified: true },
		skip: 0,
		first: 12,
		orderBy: "id_DESC"
	};

	if (isFiltered || searchString) {
		variables.orderBy = "businessName_ASC";
	}

	if (isFiltered && city && city.id) {
		variables.where.city = { id: city.id };
	}

	if (isFiltered && hood && hood.id) {
		variables.where.hood = { id: hood.id };
	}

	if (isFiltered && businessType && businessType.id) {
		variables.where.businessType = { id: businessType.id };
	}

	if (admin) {
		variables.where.verified = false;
	}

	const { loading, error, data, fetchMore, refetch } = useQuery(BUSINESSES, {
		variables
	});

	if (loading) return null;
	if (error) return null;

	return (
		<>
			<Paper elevation={0} className={classes.container}>
				<BusinessGrid
					businesses={data ? data.businesses : []}
					admin={admin}
					refetch={refetch}
				/>
			</Paper>
			<Paper elevation={0} className={classes.buttonContainer}>
				<Button
					variant="contained"
					disableElevation
					className={classes.loadButton}
					onClick={() => {
						variables.skip = data.businesses.length;
						fetchMore({
							variables,
							updateQuery: (prev, { fetchMoreResult }) => {
								if (!fetchMoreResult) return prev;
								return Object.assign({}, prev, {
									businesses: [
										...prev.businesses,
										...fetchMoreResult.businesses
									]
								});
							}
						});
					}}
				>
					Load More
					{loading && (
						<CircularProgress
							size={24}
							className={classes.progress}
						/>
					)}
				</Button>
			</Paper>
		</>
	);
};
