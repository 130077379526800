import React, { useState } from "react";
import { Redirect } from "react-router";
import netlifyIdentity from "netlify-identity-widget";
import Hero from "../../components/Hero";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	container: {
		margin: "30px 5vw"
	},
	loginButton: {
		borderRadius: "2px",
		color: "white"
	}
}));

export default ({ location }) => {
	const classes = useStyles();
	const user = netlifyIdentity.currentUser();
	const [triggerRedirect, setTriggerRedirect] = useState(false);
	let { from } = location.state || { from: { pathname: "/" } };

	netlifyIdentity.on("login", user => {
		setTriggerRedirect(true);
		netlifyIdentity.close();
	});

	if (user || triggerRedirect) {
		return <Redirect to={from} />;
	}

	return (
		<>
			<Hero page="admin" />
			<div className={classes.container}>
				<Typography>
					You must log in to view the page at {from.pathname}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					disableElevation
					className={classes.loginButton}
					onClick={() => {
						netlifyIdentity.open();
					}}
				>
					Log in
				</Button>
			</div>
		</>
	);
};
