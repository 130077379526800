import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	question: {
		marginBottom: "20px",
		color: "#424242",
		fontWeight: "bold",
		cursor: "pointer"
	},
	answer: {
		marginBottom: "20px",
		color: "#424242"
	}
}));

export default ({ faq }) => {
	const { question, answer } = faq;
	const classes = useStyles();
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Typography
				variant="body1"
				className={classes.question}
				onClick={() => {
					setVisible(!visible);
				}}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: question
					}}
				></span>
			</Typography>
			{visible && (
				<Typography variant="body1" className={classes.answer}>
					<span
						dangerouslySetInnerHTML={{
							__html: answer
						}}
					></span>
				</Typography>
			)}
		</>
	);
};
