import React from "react";
import hero from "../../images/hero_img.svg";
import { Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		color: "white"
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1,
		fontWeight: "bold"
	},
	actionButton: {
		background: "#ffc000",
		color: "white"
	},
	highlight: {
		color: "#ffc000"
	},
	heroStyle: {
		height: "400px",
		backgroundImage: `url(${hero})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		borderRadius: "0",
		marginTop: "-74px"
	},
	heroStyleMobile: {
		height: "300px",
		backgroundImage: `url(${hero})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		borderRadius: "0",
		marginTop: "-66px"
	},
	heroCaption: {
		color: "white",
		fontWeight: "bold",
		textTransform: "uppercase",
		textOverflow: "wrap",
		marginLeft: "15vw",
		marginRight: "15vw"
	},
	heroCaptionMobile: {
		color: "white",
		textTransform: "uppercase",
		textOverflow: "wrap",
		fontWeight: "bold",
		fontSize: "20px",
		marginLeft: "5vw",
		marginRight: "5vw"
	}
}));

export default ({ page, mobile }) => {
	const classes = useStyles();

	return (
		<Paper
			className={mobile ? classes.heroStyleMobile : classes.heroStyle}
			elevation={0}
		>
			{page === "home" && (
				<Typography
					variant="h4"
					className={
						mobile ? classes.heroCaptionMobile : classes.heroCaption
					}
				>
					Help us help local businesses so we can all get through the
					pandemic together.
				</Typography>
			)}
			{page === "register" && (
				<>
					<Typography
						variant="h4"
						className={
							mobile
								? classes.heroCaptionMobile
								: classes.heroCaption
						}
					>
						Add your business to{" "}
						<span className={classes.highlight}>Got My City</span>.
					</Typography>
					<Typography
						variant="h4"
						className={
							mobile
								? classes.heroCaptionMobile
								: classes.heroCaption
						}
					>
						This site will always be free.
					</Typography>
				</>
			)}
			{page === "faq" && (
				<Typography
					variant="h4"
					className={
						mobile ? classes.heroCaptionMobile : classes.heroCaption
					}
				>
					Frequently asked questions
				</Typography>
			)}
			{page === "admin" && (
				<Typography
					variant="h4"
					className={
						mobile ? classes.heroCaptionMobile : classes.heroCaption
					}
				>
					G<span className={classes.highlight}>M</span>.C Admin
				</Typography>
			)}
		</Paper>
	);
};
