import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FaqItem from "../FaqItem";

const useStyles = makeStyles(theme => ({
	container: {
		margin: "30px 20vw"
	},
	containerMobile: {
		margin: "30px 5vw"
	},
	faqHeader: {
		marginBottom: "30px",
		color: "#424242"
	}
}));

export default ({ data, title, mobile }) => {
	const classes = useStyles();

	return (
		<div className={mobile ? classes.containerMobile : classes.container}>
			<Typography variant="h5" className={classes.faqHeader}>
				{title}
			</Typography>
			{data.faqs.map(faq => (
				<FaqItem faq={faq} key={faq.id} />
			))}
		</div>
	);
};
