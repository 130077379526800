import React, { useState } from "react";
import { Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchBar from "../../components/SearchBar";
import Hero from "../../components/Hero";
import BusinessGridContainer from "../../components/BusinessGrid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FilterContainer from "../../components/Filter";

const useStyles = makeStyles(theme => ({
	searchContainer: {
		marginTop: "-50px",
		marginLeft: "20vw",
		marginRight: "20vw"
	},
	searchContainerMobile: {
		marginTop: "-50px",
		marginLeft: "5vw",
		marginRight: "5vw"
	},
	subCaption: {
		color: "white",
		textTransform: "uppercase",
		textOverflow: "wrap"
	},
	subCaptionMobile: {
		color: "white",
		textTransform: "uppercase",
		textOverflow: "wrap",
		fontSize: "12px"
	},
	divider: {
		margin: "60px 5vw"
	},

	dividerMobile: {
		margin: "30px 5vw 20px"
	}
}));

export default () => {
	const classes = useStyles();
	const [searchString, setSearchString] = useState("");
	const [hood, setHood] = useState({ id: "", name: "" });
	const [city, setCity] = useState({ id: "", name: "", province: "" });
	const [businessType, setBusinessType] = useState({ id: "", name: "" });
	const [isFiltered, setIsFiltered] = useState(false);
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<>
			<Hero page="home" mobile={mobile} />
			<div
				className={
					mobile
						? classes.searchContainerMobile
						: classes.searchContainer
				}
			>
				<Typography
					variant="subtitle1"
					className={
						mobile ? classes.subCaptionMobile : classes.subCaption
					}
				>
					Help a local business flatten the curve
				</Typography>
				<SearchBar
					setSearchString={setSearchString}
					loading={loading}
					setLoading={setLoading}
					mobile={mobile}
					city={city}
				/>
			</div>
			<Divider
				className={mobile ? classes.dividerMobile : classes.divider}
			/>
			<FilterContainer
				setHood={setHood}
				hood={hood}
				setCity={setCity}
				city={city}
				setBusinessType={setBusinessType}
				businessType={businessType}
				setIsFiltered={setIsFiltered}
				isFiltered={isFiltered}
				mobile={mobile}
			/>
			<BusinessGridContainer
				searchString={searchString}
				hood={hood}
				city={city}
				businessType={businessType}
				isFiltered={isFiltered}
			/>
		</>
	);
};
