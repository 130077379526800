import ApolloClient from "apollo-boost";

const TOKEN = process.env.REACT_APP_SERVER_TOKEN || "";
const uri =
	process.env.REACT_APP_SERVER_ENDPOINT ||
	"https://us1.prisma.sh/zareef-baksh-6d0cac/gotMyCity/dev";

const client = new ApolloClient({
	uri,
	request: operation => {
		operation.setContext({
			headers: {
				authorization: TOKEN ? `Bearer ${TOKEN}` : ""
			}
		});
	}
});

export default client;
