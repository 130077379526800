import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import FaqList from "./FaqList";

const FAQS = gql`
	query Faqs($type: FaqType!) {
		faqs(where: { type: $type }, orderBy: order_ASC) {
			id
			type
			question
			answer
		}
	}
`;

export default ({ type, title, mobile }) => {
	const { loading, error, data } = useQuery(FAQS, { variables: { type } });
	if (loading) return null;
	if (error) return null;

	return <FaqList title={title} data={data} mobile={mobile} />;
};
