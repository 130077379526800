import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./apollo/client";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<Routes />
				</BrowserRouter>
			</ApolloProvider>
		</ThemeProvider>
	);
}

export default App;
