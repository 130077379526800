import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hero from "../../components/Hero";
import RegisterForm from "../../components/RegisterForm";
import RegisterPreview from "../../components/RegisterPreview";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	mainContent: {
		margin: "60px 20vw 0",
		flexGrow: 1
	},
	mainContentMobile: {
		margin: "40px 5vw 0",
		flexGrow: 1
	},
	bodyCaption: {
		marginBottom: "20px"
	},
	paper: {
		padding: theme.spacing(5)
	},
	field: {
		marginBottom: "20px"
	},
	actionButton: {
		background: "#ffc000",
		color: "white",
		borderRadius: "2px",
		padding: "10px 25px"
	}
}));

export default () => {
	const classes = useStyles();
	const [formData, setFormData] = useState({
		businessType: "",
		ctaType: "",
		hood: "",
		city: ""
	});

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<>
			<Hero page="register" mobile={mobile} />
			<div
				className={
					mobile ? classes.mainContentMobile : classes.mainContent
				}
			>
				<Typography
					className={classes.bodyCaption}
					color="textSecondary"
				>
					Fill out the form below to add your business to Got My City.
					We will review and approve all posts; we'll let you know
					once your post is approved and goes live.
				</Typography>
				<Grid container spacing={3}>
					<Grid item md={6}>
						<RegisterForm
							formData={formData}
							setFormData={setFormData}
							mobile={mobile}
						/>
					</Grid>
					{!mobile && (
						<Grid item md={6}>
							<RegisterPreview formData={formData} />
						</Grid>
					)}
				</Grid>
			</div>
		</>
	);
};
