import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import RegisterForm from "./RegisterForm";

const HOODS = gql`
	query Hoods($city: CityWhereInput) {
		hoods(where: { city: $city }, orderBy: name_ASC) {
			id
			name
		}
	}
`;

const CITIES = gql`
	{
		cities(orderBy: province_ASC) {
			id
			name
			province
		}
	}
`;

const BUSINESS_TYPES = gql`
	{
		businessTypes(orderBy: name_ASC) {
			id
			name
		}
	}
`;

const CTA_TYPES = gql`
	{
		ctaTypes(orderBy: name_ASC) {
			id
			name
		}
	}
`;

export default ({ formData, setFormData, mobile }) => {
	const { data: citiesData } = useQuery(CITIES);
	const { data: hoodsData } = useQuery(HOODS, {
		variables: {
			city: { id: formData.city }
		}
	});
	const { data: businessTypesData } = useQuery(BUSINESS_TYPES);
	const { data: ctaTypesData } = useQuery(CTA_TYPES);
	return (
		<RegisterForm
			formData={formData}
			setFormData={setFormData}
			cities={citiesData ? citiesData.cities : []}
			hoods={hoodsData ? hoodsData.hoods : []}
			businessTypes={
				businessTypesData ? businessTypesData.businessTypes : []
			}
			ctaTypes={ctaTypesData ? ctaTypesData.ctaTypes : []}
			mobile={mobile}
		/>
	);
};
