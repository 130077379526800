import React from "react";
import { Grid } from "@material-ui/core";
import BusinessCard from "../BusinessCard";

export default ({ businesses, admin, refetch }) => {
	return (
		<Grid container spacing={3}>
			{businesses.map(business => (
				<Grid
					item
					xs={12}
					sm={6}
					md={4}
					lg={3}
					xl={2}
					key={business.id}
				>
					<BusinessCard
						data={business}
						admin={admin}
						refetch={refetch}
					/>
				</Grid>
			))}
		</Grid>
	);
};
