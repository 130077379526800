import React from "react";
import { Route, Switch, Redirect } from "react-router";
import PrivateRoute from "./PrivateRoute";
import MenuBar from "../components/MenuBar";
import Home from "../pages/Home";
import Register from "../pages/Register";
import Faq from "../pages/Faq";
import Admin from "../pages/Admin";
import Login from "../pages/Login";
import Footer from "../components/Footer";

export default () => (
	<>
		<MenuBar />
		<Switch>
			<Route exact path="/" name="home" component={Home} />
			<Route
				exact
				path="/register"
				name="register"
				component={Register}
			/>
			<Route exact path="/faq" name="faq" component={Faq} />
			<PrivateRoute exact path="/admin" name="admin" component={Admin} />
			<Route exact path="/login" component={Login} />
			<Redirect from="*" to="/" />
		</Switch>
		<Footer />
	</>
);
