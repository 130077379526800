import React from "react";
import {
	Button,
	Dialog,
	Paper,
	DialogContent,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import SentImage from "../../images/sent-image.svg";

const useStyles = makeStyles(theme => ({
	container: {
		textAlign: "center"
	},
	imageContainer: {
		padding: "5vw"
	},
	thankYouHeading: {
		fontWeight: "bold",
		color: "#424242"
	},
	thankYouSubHeading: {
		color: "#424242",
		marginTop: "5px"
	},
	thankYouHeadingMobile: {
		fontWeight: "bold",
		color: "#424242",
		fontSize: "20px"
	},
	thankYouSubHeadingMobile: {
		color: "#424242",
		marginTop: "5px",
		fontSize: "14px"
	},
	dialogActions: {
		margin: "30px",
		textAlign: "center"
	},
	primaryButton: {
		color: "#FFFFFF",
		margin: "10px",
		borderRadius: "2px"
	},
	secondaryButton: {
		margin: "10px",
		borderRadius: "2px",
		backgroundColor: "#f5f5f5"
	},
	image: {
		width: "100%"
	}
}));

export default ({ dialogOpen, setDialogOpen, resetForm, mobile }) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Dialog
			onClose={() => {
				setDialogOpen(false);
			}}
			open={dialogOpen}
			maxWidth="lg"
		>
			<DialogContent className={classes.container}>
				<Paper elevation={0} className={classes.imageContainer}>
					<img
						src={SentImage}
						alt="Form Success"
						className={classes.image}
					/>
				</Paper>
				<Typography
					variant="h4"
					className={
						mobile
							? classes.thankYouHeadingMobile
							: classes.thankYouHeading
					}
				>
					Thank you for your submission!
				</Typography>
				<Typography
					variant="body1"
					className={
						mobile
							? classes.thankYouSubHeadingMobile
							: classes.thankYouSubHeading
					}
				>
					We will be in contact once we have reviewed it.
				</Typography>
			</DialogContent>
			<DialogContent className={classes.dialogActions}>
				<Button
					onClick={() => {
						history.push("/home");
					}}
					variant="contained"
					color="neutral"
					disableElevation
					className={classes.secondaryButton}
				>
					Return Home
				</Button>
				<Button
					onClick={resetForm}
					color="primary"
					variant="contained"
					disableElevation
					className={classes.primaryButton}
				>
					Add Another
				</Button>
			</DialogContent>
		</Dialog>
	);
};
