import React from "react";
import { Typography, Paper, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hero from "../../components/Hero";
import FaqList from "../../components/FaqList";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const CUSTOMER_TITLE = "For Shoppers and Patrons";
const BUSINESS_TITLE = "For Businesses";

const useStyles = makeStyles(theme => ({
	container: {
		margin: "30px 20vw"
	},
	containerMobile: {
		margin: "30px 5vw"
	},
	faqHeader: {
		marginBottom: "30px",
		color: "#424242"
	}
}));

export default () => {
	const classes = useStyles();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<>
			<Hero page="faq" mobile={mobile} />
			<FaqList type="CUSTOMER" title={CUSTOMER_TITLE} mobile={mobile} />
			<FaqList type="BUSINESS" title={BUSINESS_TITLE} mobile={mobile} />
			<Paper
				className={mobile ? classes.containerMobile : classes.container}
				elevation={0}
			>
				<Typography variant="h5" className={classes.faqHeader}>
					General Inquiries
				</Typography>
				<Typography>
					For all general inquires or quetions not answered above,
					please contact us at <span></span>
					<Link color="secondary" href="mailto:info@gotmy.city">
						info@gotmy.city
					</Link>
				</Typography>
			</Paper>
		</>
	);
};
