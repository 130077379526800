import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BusinessCard from "../BusinessCard";

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(5)
	},
	ellipse: {
		background: "#424242",
		height: "4px",
		width: "4px",
		borderRadius: "2px",
		display: "inline-block",
		margin: "2px 4px"
	}
}));

export default ({ formData }) => {
	const classes = useStyles();

	return (
		<Paper className={classes.paper} elevation={0} variant="outlined">
			<BusinessCard data={formData} />
		</Paper>
	);
};
