import React, { useState } from "react";
import {
	Typography,
	Button,
	Card,
	CardMedia,
	CardContent,
	CardActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "../../images/placeholder-img.svg";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const VERIFY_BUSINESS = gql`
	mutation VerifyBusiness($where: BusinessWhereUniqueInput!) {
		updateBusiness(data: { verified: true }, where: $where) {
			id
			verified
		}
	}
`;

const useStyles = makeStyles(theme => ({
	ellipse: {
		background: "#424242",
		height: "4px",
		width: "4px",
		borderRadius: "2px",
		display: "inline-block",
		margin: "2px 4px"
	},
	card: {
		height: "100%",
		display: "flex",
		flexDirection: "column"
	},
	cardContent: {
		flexGrow: 1
	},
	verify: {
		color: "white",
		borderRadius: "2px"
	}
}));

export default ({ data, admin, refetch }) => {
	const classes = useStyles();
	const [elevation, setElevtaion] = useState(0);
	const [verifyBusiness] = useMutation(VERIFY_BUSINESS, {
		onCompleted: ({ updateBusiness }) => {
			refetch();
		}
	});

	let {
		businessName,
		description,
		businessTypeDisplay,
		hoodDisplay,
		cityDisplay,
		ctaTypeDisplay,
		ctaLink,
		imageUrl,
		id
	} = data;

	if (data.hood && data.hood.name) {
		hoodDisplay = data.hood.name;
	}

	if (!data.hood && data.city) {
		hoodDisplay = data.city.name;
	}

	if (!hoodDisplay && cityDisplay) {
		hoodDisplay = cityDisplay;
	}

	if (data.businessType.name) {
		businessTypeDisplay = data.businessType.name;
	}

	if (data.ctaType.name) {
		ctaTypeDisplay = data.ctaType.name;
	}

	return (
		<Card
			elevation={elevation}
			variant={elevation === 0 ? "outlined" : "elevation"}
			onMouseEnter={() => {
				setElevtaion(3);
			}}
			onMouseLeave={() => {
				setElevtaion(0);
			}}
			className={classes.card}
		>
			<CardMedia
				component="img"
				alt={businessName || "Company Name"}
				height="140"
				image={imageUrl || ImageIcon}
				title={businessName || "Company Name"}
			/>
			<CardContent className={classes.cardContent}>
				<Typography
					gutterBottom
					variant="h5"
					component="h2"
					color="textSecondary"
					className={classes.cardTitle}
				>
					{businessName || "Company Name"}
				</Typography>
				<Typography
					gutterBottom
					variant="subtitle2"
					color="textSecondary"
				>
					{businessTypeDisplay || "Category"}
					<div className={classes.ellipse}></div>
					{hoodDisplay || "Location"}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{description ||
						"Short description, no more than 140 characters."}
				</Typography>
			</CardContent>

			<CardActions className={classes.cardActions}>
				{ctaTypeDisplay !== "Order by Phone" && (
					<Button
						size="small"
						color="secondary"
						href={ctaLink}
						target="_blank"
						rel="noreferrer"
						className="businessCTA"
					>
						{ctaTypeDisplay || "Call to Action"}
					</Button>
				)}

				{ctaTypeDisplay === "Order by Phone" && (
					<>
						<Button
							size="small"
							color="secondary"
							rel="noreferrer"
							href={`tel:${ctaLink}`}
							className="businessCTA"
						>
							{`${ctaTypeDisplay}: ${ctaLink ||
								"Call to Action"}`}
						</Button>
					</>
				)}

				{admin && (
					<>
						<Button
							size="small"
							color="secondary"
							variant="contained"
							disableElevation
							className={classes.verify}
							onClick={() => {
								verifyBusiness({
									variables: { where: { id } }
								});
							}}
						>
							Verify
						</Button>
					</>
				)}
			</CardActions>
		</Card>
	);
};
