import React from "react";
import {
	Button,
	Divider,
	Paper,
	Typography,
	AppBar,
	Toolbar,
	IconButton,
	Hidden,
	ButtonGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import fb from "../../images/fb.svg";
import twitter from "../../images/twitter.svg";
import ig from "../../images/ig.svg";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
	container: {
		margin: "40px 5vw",
		flexGrow: 1
	},
	footerButton: {
		textTransform: "none"
	},
	copyright: {
		textAlign: "center",
		backgroundColor: "#F5F5F5",
		padding: "10px 0"
	},
	copyrightText: {
		color: "#424242",
		fontSize: "14px"
	},
	copyrightTextMobile: {
		color: "#424242",
		fontSize: "10px"
	},
	test: {
		flexGrow: 1,
		textAlign: "right"
	},
	root: {
		flexGrow: 1
	},
	toolbar: {
		margin: "30px 5vw 50px"
	},
	toolbarMobile: {
		margin: "20px 5vw 10px",
		justifyContent: "center"
	},
	share: {
		flexGrow: 1,
		textAlign: "right",
		color: "#424242"
	},
	shareMobile: {
		width: "100%",
		textAlign: "center",
		color: "#424242"
	},
	footer: {
		marginTop: "60px"
	},
	socialButton: {
		padding: "0",
		margin: "0 0 0 10px"
	},
	socialButtonMobile: {
		"&:not(:last-child)": {
			borderRight: 0
		},
		padding: "10px 0 20px"
	},
	socialImgMobile: {
		width: "30px"
	},
	iconButtonGroup: {
		justifyContent: "center"
	}
}));

export default () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<footer className={classes.footer}>
			<Divider />
			<div className={classes.root}>
				<AppBar position="static" color="transparent" elevation={0}>
					<Toolbar
						disableGutters
						className={
							mobile ? classes.toolbarMobile : classes.toolbar
						}
					>
						<Button
							className={classes.footerButton}
							onClick={() => {
								history.push("/");
							}}
						>
							Home
						</Button>
						<Button
							className={classes.footerButton}
							onClick={() => {
								history.push("/faq");
							}}
						>
							Got Questions?
						</Button>
						<Button
							className={classes.footerButton}
							color="primary"
							onClick={() => {
								history.push("/register");
							}}
						>
							Get Sharing
						</Button>
						<Hidden xsDown>
							<Typography
								variant="body2"
								component={mobile ? "p" : "span"}
								className={
									mobile ? classes.shareMobile : classes.share
								}
							>
								Find us on
								<IconButton
									className={classes.socialButton}
									href="https://www.facebook.com/Got-My-City-100713958255552"
									target="_blank"
								>
									<img src={fb} alt="facebook-icon" />
								</IconButton>
								<IconButton
									className={classes.socialButton}
									href="https://twitter.com/GotMyCity"
									target="_blank"
								>
									<img src={twitter} alt="twitter-icon" />
								</IconButton>
								<IconButton
									className={classes.socialButton}
									href="https://www.instagram.com/gotmycity/"
									target="_blank"
								>
									<img src={ig} alt="ig-icon" />
								</IconButton>
							</Typography>
						</Hidden>
					</Toolbar>
					<Hidden smUp>
						<Typography
							variant="body2"
							component={mobile ? "p" : "span"}
							className={
								mobile ? classes.shareMobile : classes.share
							}
						>
							Find us on
						</Typography>
						<ButtonGroup
							variant="text"
							className={classes.iconButtonGroup}
						>
							<Button
								className={classes.socialButtonMobile}
								href="https://www.facebook.com/Got-My-City-100713958255552"
								target="_blank"
							>
								<img
									src={fb}
									alt="facebook-icon"
									className={classes.socialImgMobile}
								/>
							</Button>
							<Button
								className={classes.socialButtonMobile}
								href="https://twitter.com/GotMyCity"
								target="_blank"
							>
								<img
									src={twitter}
									alt="twitter-icon"
									className={classes.socialImgMobile}
								/>
							</Button>
							<Button
								className={classes.socialButtonMobile}
								href="https://www.instagram.com/gotmycity/"
								target="_blank"
							>
								<img
									src={ig}
									alt="ig-icon"
									className={classes.socialImgMobile}
								/>
							</Button>
						</ButtonGroup>
					</Hidden>
				</AppBar>
			</div>
			<Paper elevation={0} className={classes.copyright}>
				<Typography
					className={
						mobile
							? classes.copyrightTextMobile
							: classes.copyrightText
					}
				>
					© GOT MY CITY, 2020. All Rights Reserved.
				</Typography>
			</Paper>
		</footer>
	);
};
