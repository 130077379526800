import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InputBase, Button, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
	root: {
		padding: "4px",
		display: "flex",
		alignItems: "center",
		width: "60vw",
		borderRadius: "2px"
	},
	rootMobile: {
		padding: "4px",
		display: "flex",
		alignItems: "center",
		width: "100%",
		borderRadius: "2px"
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
		borderRadius: "2px"
	},
	inputMobile: {
		marginLeft: theme.spacing(1),
		flex: 1,
		borderRadius: "2px",
		fontSize: "14px"
	},
	actionButton: {
		background: "#ffc000",
		color: "white",
		borderRadius: "2px",
		padding: "5px 20px"
	},
	wrapper: {
		postion: "relative"
	},
	progress: {
		color: "white",
		position: "absolute",
		left: "50%",
		marginLeft: -12
	}
}));

export default ({ setSearchString, loading, mobile, city }) => {
	const classes = useStyles();
	let searchInputValue = "";

	return (
		<Paper
			component="form"
			className={mobile ? classes.rootMobile : classes.root}
			elevation={4}
		>
			<SearchIcon />
			<InputBase
				className={mobile ? classes.inputMobile : classes.input}
				placeholder={
					mobile
						? "Search for local businesses..."
						: `Search for cafes, restaurants and other ${
								city && city.name ? city.name + " " : ""
						  }businesses`
				}
				inputProps={{
					"aria-label": mobile
						? "Search for local businesses..."
						: `Search for cafes, restaurants and other ${
								city && city.name ? city.name + " " : ""
						  }businesses`
				}}
				onChange={e => {
					searchInputValue = e.target.value;
				}}
				onKeyPress={e => {
					if (e.which === 13) {
						e.preventDefault();
						setSearchString(searchInputValue);
					}
				}}
			/>

			<Button
				className={classes.actionButton}
				onClick={() => {
					setSearchString(searchInputValue);
				}}
			>
				Search
				{loading && (
					<CircularProgress size={24} className={classes.progress} />
				)}
			</Button>
		</Paper>
	);
};
