import React, { useState } from "react";
import {
	TextField,
	Divider,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Button,
	Typography,
	Tooltip,
	ListSubheader
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ConfirmNewBusinessModal from "../ConfirmNewBusinessModal";
import ReactFilestack from "filestack-react";

const CREATE_BUSINESS = gql`
	mutation CreateBusiness($data: BusinessCreateInput!) {
		createBusiness(data: $data) {
			id
		}
	}
`;

const useStyles = makeStyles(theme => ({
	mainContent: {
		margin: "60px 20vw 0",
		flexGrow: 1
	},
	bodyCaption: {
		marginBottom: "20px"
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary
	},
	field: {
		marginBottom: "20px",
		"&:hover": {
			borderColor: theme.palette.secondary.main,
			color: theme.palette.secondary.main
		}
	},
	actionButton: {
		color: "white",
		backgroundColor: theme.palette.primary,
		borderRadius: "2px",
		padding: "10px 25px",
		"&:disabled": {
			background: "rgba(255,192,0,0.2)",
			color: "white"
		}
	},
	selectButton: {
		backgroundColor: "#F5F5F5",
		borderRadius: "2px"
	},
	imageWrapper: {
		display: "flex",
		padding: "20px"
	},
	imagePlaceholder: {
		flexGrow: 1,
		color: "#6a6a6a",
		lineHeight: "36px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	imageHelpTooltip: {
		color: "#6a6a6a",
		marginTop: "6px",
		marginLeft: "10px"
	}
}));

export default ({
	formData,
	setFormData,
	hoods,
	businessTypes,
	ctaTypes,
	mobile,
	cities
}) => {
	const classes = useStyles();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [createBusiness] = useMutation(CREATE_BUSINESS, {
		onCompleted: insertData => {
			setDialogOpen(true);
		}
	});

	const handleFieldChange = (e, child) => {
		const newFormData = JSON.parse(JSON.stringify(formData));
		newFormData[e.target.name] = e.target.value;

		if (
			["hood", "city", "businessType", "ctaType"].includes(e.target.name)
		) {
			newFormData[`${e.target.name}Display`] = e.target.value
				? child.props.children
				: "";
		}

		setFormData(newFormData);
	};

	const resetForm = () => {
		setFormData({
			businessType: "",
			ctaType: "",
			hood: "",
			contactName: "",
			contactEmail: "",
			businessName: "",
			ctaLink: "",
			description: "",
			city: ""
		});
		setDialogOpen(false);
	};

	const FILESTACK_API_KEY = "A3s0v7UZS3ira55Tlqfn1z";

	const organiseCities = cities => {
		let out = {};

		cities.map(city => {
			if (out[city.province]) {
				out[city.province].push(city);
			} else {
				out[city.province] = [city];
			}

			return null;
		});

		return Object.entries(out);
	};

	return (
		<>
			<form noValidate autoComplete="off">
				<TextField
					id="contactName"
					name="contactName"
					label="Name"
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
					onChange={handleFieldChange}
					value={formData.contactName}
				/>
				<TextField
					id="contactEmail"
					name="contactEmail"
					label="Email"
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
					onChange={handleFieldChange}
					value={formData.contactEmail}
				/>
				<TextField
					id="businessName"
					name="businessName"
					label="Company/Location Name"
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
					onChange={handleFieldChange}
					value={formData.businessName}
				/>
				<Divider className={classes.field} />
				<div className={classes.imageWrapper}>
					<Typography
						variant="body1"
						className={classes.imagePlaceholder}
					>
						{formData.imageUrl ? formData.imageUrl : "Add an image"}
					</Typography>
					<ReactFilestack
						apikey={FILESTACK_API_KEY}
						onSuccess={res => {
							let url = res.filesUploaded[0].url;
							let stateClone = JSON.parse(
								JSON.stringify(formData)
							);
							stateClone.imageUrl = url;
							setFormData(stateClone);
						}}
						customRender={({ onPick }) => (
							<Button
								color="default"
								variant="contained"
								disableElevation
								className={classes.selectButton}
								onClick={onPick}
							>
								Select
							</Button>
						)}
					/>
					<Tooltip
						title="Recommended minimum size, 1080x760. JPEG, GIF and PNG file formats accepted."
						placement="top"
					>
						<HelpIcon className={classes.imageHelpTooltip} />
					</Tooltip>
				</div>
				<FormControl
					variant="outlined"
					className={classes.field}
					fullWidth
					color="secondary"
				>
					<InputLabel id="city-label">City</InputLabel>
					<Select
						labelId="city-label"
						id="city"
						name="city"
						value={formData.city}
						onChange={handleFieldChange}
						label="City"
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{cities &&
							organiseCities(cities).map(province => [
								<ListSubheader key={province[0]}>
									{province[0]}
								</ListSubheader>,
								province[1].map(({ id, name }) => (
									<MenuItem value={id} key={id}>
										{name}
									</MenuItem>
								))
							])}
					</Select>
				</FormControl>
				{formData.city && (
					<FormControl
						variant="outlined"
						className={classes.field}
						fullWidth
						color="secondary"
					>
						<InputLabel id="hood-label">Neighbourhood</InputLabel>
						<Select
							labelId="hood-label"
							id="hood"
							name="hood"
							value={formData.hood}
							onChange={handleFieldChange}
							label="Neighbourhood"
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{hoods &&
								hoods.map(({ id, name }) => (
									<MenuItem value={id} key={id}>
										{name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				)}

				<FormControl
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
				>
					<InputLabel id="businessType-label">Category</InputLabel>
					<Select
						labelId="businessType-label"
						id="businessType"
						name="businessType"
						value={formData.businessType}
						label="Category"
						onChange={handleFieldChange}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{businessTypes &&
							businessTypes.map(({ id, name }) => (
								<MenuItem value={id} key={id}>
									{name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<TextField
					id="description"
					name="description"
					label="Description"
					placeholder="Add a few words about your business..."
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
					multiline
					rows={3}
					rowsMax={5}
					helperText={`${
						formData.description ? formData.description.length : 0
					}/140`}
					onChange={handleFieldChange}
					error={
						formData.description &&
						formData.description.length > 140
					}
					value={formData.description}
				/>
				<FormControl
					variant="outlined"
					color="secondary"
					className={classes.field}
					fullWidth
				>
					<InputLabel id="ctaType-label">Call to action</InputLabel>
					<Select
						labelId="ctaType-label"
						id="ctaType"
						name="ctaType"
						value={formData.ctaType}
						onChange={handleFieldChange}
						label="Call to Action"
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{ctaTypes &&
							ctaTypes.map(({ id, name }) => (
								<MenuItem value={id} key={id}>
									{name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{formData.ctaTypeDisplay &&
					formData.ctaTypeDisplay !== "Order by Phone" && (
						<TextField
							id="ctaLink"
							name="ctaLink"
							label="Link"
							variant="outlined"
							color="secondary"
							className={classes.field}
							fullWidth
							onChange={handleFieldChange}
						/>
					)}

				{formData.ctaTypeDisplay &&
					formData.ctaTypeDisplay === "Order by Phone" && (
						<TextField
							id="ctaLink"
							name="ctaLink"
							label="Phone"
							variant="outlined"
							color="secondary"
							className={classes.field}
							fullWidth
							onChange={handleFieldChange}
						/>
					)}

				<Button
					size="large"
					className={classes.actionButton}
					variant="contained"
					disableElevation
					color="primary"
					disabled={
						!(
							formData.contactName &&
							formData.contactEmail &&
							formData.businessName &&
							formData.city &&
							formData.businessType &&
							(formData.description &&
								formData.description.length <= 140) &&
							formData.ctaType &&
							formData.ctaLink
						)
					}
					onClick={() => {
						const {
							contactName,
							contactEmail,
							businessName,
							businessType,
							description,
							ctaType,
							ctaLink,
							hood,
							city,
							imageUrl
						} = formData;

						let mutationData = {
							contactName,
							contactEmail,
							businessName,
							businessType: {
								connect: {
									id: businessType
								}
							},
							description,
							ctaType: {
								connect: {
									id: ctaType
								}
							},
							city: {
								connect: {
									id: city
								}
							},
							ctaLink,
							imageUrl
						};
						if (hood) {
							mutationData.hood = {
								connect: {
									id: hood
								}
							};
						}
						createBusiness({ variables: { data: mutationData } });
					}}
				>
					Submit Business
				</Button>
			</form>

			<ConfirmNewBusinessModal
				dialogOpen={dialogOpen}
				setDialogOpen={setDialogOpen}
				resetForm={resetForm}
				mobile={mobile}
			/>
		</>
	);
};
